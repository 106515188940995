<template>
  <div class="df-flex-sm df-flex-col program-infos">
    <p>
      {{ program.sprayings.length }}
      {{
        $t(
          `DiseasesControl.program_item.spraying${isSingleSpraying ? '' : 's'}`
        )
      }}
    </p>
    <div class="df-flex-l flex-nowrap">
      <div class="df-flex-xm df-flex-col flex-grow-1">
        <h2>{{ program.name }}</h2>
        <span>
          {{ $t('DiseasesControl.program_item.sowing_on') }}:
          {{ programDate }}
        </span>
      </div>
      <div class="df-flex-xm df-flex-sm-m df-flex-col flex-grow-0">
        <program-secondary-infos
          class="justify-end"
          :justify-between="justifyBetween"
          :program="program"
        />
        <program-cost class="justify-end" :program="program" />
      </div>
    </div>
  </div>
</template>

<script>
import ProgramCost from '@/modules/diseasesControl/components/ProgramCost.vue'
import ProgramSecondaryInfos from '@/modules/diseasesControl/components/ProgramSecondaryInfos.vue'

export default {
  name: 'ProgramInfos',

  components: {
    ProgramCost,
    ProgramSecondaryInfos,
  },

  props: {
    justifyBetween: {
      default: false,
      type: Boolean,
    },
    program: {
      required: true,
      type: Object,
    },
  },

  computed: {
    isSingleSpraying() {
      return this.program.sprayings.length === 1
    },
    programDate() {
      return (
        (this.program.date && this.program.date.formatDate()) ||
        this.program.estimated_sowing_date.formatDate()
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.program-infos {
  padding: 12px 16px;
  background-color: #f8f9f8;
  border: 1px solid #e6e9e6;
  border-radius: 8px;

  h2 {
    overflow: hidden;
    color: #1a2b46;
    text-overflow: ellipsis;
    font-family: 'Rubik';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  p {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  span {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
</style>
