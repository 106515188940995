<template>
  <div class="program-item">
    <section class="df-flex-sm df-flex-col program__infos">
      <slot name="farm"></slot>
      <div class="df-flex-xm align-center">
        <font-awesome-icon color="#F2CD12" icon="star" />
        <h3>{{ programRate }}</h3>
        <span>•</span>
        <span>
          {{ program.sprayings.length }}
          {{
            $t(
              `DiseasesControl.program_item.spraying${
                isSingleSpraying ? '' : 's'
              }`
            )
          }}
        </span>
      </div>
      <div class="df-flex-l df-flex-col">
        <div class="df-flex-xm flex-nowrap">
          <div class="df-flex-xm df-flex-col">
            <v-tooltip v-if="programName.length > 42" top color="#fff">
              <template #activator="{ on }">
                <h2 v-on="on">{{ programName }}</h2>
              </template>
              <span class="tooltip__content">
                {{ programName }}
              </span>
            </v-tooltip>
            <h2 v-else>{{ programName }}</h2>
            <p>
              {{ $t('DiseasesControl.program_item.sowing_on') }}:
              {{ programDate }}
            </p>
          </div>
          <df-dropdown-list
            v-if="!isComparisonScreen"
            icon="ellipsis-v"
            :items="programActions"
          />
        </div>
        <div v-if="isConfiguredProgram && hasInfo">
          <program-secondary-infos justify-between :program="program" />
        </div>
        <div v-if="isConfiguredProgram" class="df-flex-sm df-flex-col">
          <program-cost column :program="program" />
        </div>
      </div>
    </section>
    <section
      v-if="program.sprayings.length"
      ref="programSprayings"
      class="program__sprayings"
    >
      <div v-if="shouldShowExecuteButton" class="sprayings__action">
        <df-button
          color-icon="#39AF49"
          left-icon="tractor"
          outlined
          variant="text-blue"
          width="100%"
          @click="executeInSeason"
        >
          {{ $t('DiseasesControl.program_item.execute_in_season') }}
        </df-button>
      </div>
      <program-spraying-item
        v-for="spraying in program.sprayings"
        :key="spraying.id"
        :crop-name="cropName"
        :is-comparison-screen="isComparisonScreen"
        :spraying="spraying"
      />
    </section>
    <section v-if="showProgramActions" class="program__actions">
      <div
        v-if="isConfiguredProgram"
        class="df-flex-sm df-flex-col actions__add-spraying"
      >
        <p>
          {{ $t('DiseasesControl.program_item.suggested_sprayings_quantity') }}
          :<br />
          {{ program.spraying_quantity_suggested[0] }}
          <span v-if="program.spraying_quantity_suggested[1]">
            {{ $t('DiseasesControl.program_item.to') }}
            {{ program.spraying_quantity_suggested[1] }}
          </span>
        </p>
        <span
          v-if="program.white_mold_control"
          class="add-sprayings__white-mold"
        >
          {{ $t('DiseasesControl.program_item.white_mold_spraying') }}
        </span>
        <df-button
          outlined
          variant="text-blue"
          width="100%"
          @click="addSpraying"
        >
          {{
            $t('DiseasesControl.program_item.insert_spraying', [
              formatOrdinal(program.sprayings.length + 1),
            ])
          }}
        </df-button>
      </div>
      <df-button
        v-else
        outlined
        variant="text-blue"
        width="100%"
        @click="configureProgram"
      >
        {{ $t('DiseasesControl.program_item.configure_program') }}
      </df-button>
    </section>
    <add-program-modal
      v-if="isAddProgramModalOpened"
      :program-id="program.id"
      @close="closeAddProgramModal"
    />
    <df-copy-modal
      v-if="isCopyModalOpened"
      :description="$t('DiseasesControl.program_item.select_field_description')"
      :loading="loadingCopyModal"
      :title="$t('DiseasesControl.program_item.copy_program')"
      @action-click="copyProgram"
      @close="closeCopyModal"
    >
      <template #card>
        <program-infos :program="program" />
      </template>
    </df-copy-modal>
    <df-delete-modal
      v-if="isDeleteProgramModalOpened"
      persistent
      :text="$t('DiseasesControl.program_item.delete_program_text')"
      :title="$t('DiseasesControl.program_item.delete_program_title')"
      :width="690"
      @action-click="deleteProgram"
      @close="closeDeleteProgramModal"
    >
      <template #content>
        <program-infos :program="program" />
      </template>
    </df-delete-modal>
    <add-spraying-modal
      v-if="isAddSprayingModalOpened"
      :crop-name="cropName"
      :program-id="program.id"
      :sowing-id="program.sowing_id"
      @close="closeAddSprayingModal"
    />
    <execute-program-modal
      v-if="isExecuteProgramModalOpened"
      :program="program"
      @close="closeExecuteProgramModal"
    />
  </div>
</template>

<script>
const AddProgramModal = () =>
  import('@/modules/diseasesControl/components/modal/AddProgramModal.vue')
const AddSprayingModal = () =>
  import('@/modules/sowingView/components/modal/AddSprayingModal.vue')
const DfCopyModal = () => import('@/lib/Modal/DfCopyModal.vue')
const DfDeleteModal = () => import('@/lib/Modal/DfDeleteModal.vue')
import diseasesControlService from '@/modules/diseasesControl/services/diseasesControlService'
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfDropdownList from '@/lib/components/List/Dropdown/DfDropdownList.vue'
const ExecuteProgramModal = () =>
  import('@/modules/diseasesControl/components/modal/ExecuteProgramModal.vue')
import ProgramCost from '@/modules/diseasesControl/components/ProgramCost.vue'
import ProgramInfos from '@/modules/diseasesControl/components/ProgramInfos.vue'
import ProgramSecondaryInfos from '@/modules/diseasesControl/components/ProgramSecondaryInfos.vue'
import ProgramSprayingItem from '@/modules/diseasesControl/components/list/ProgramSprayingItem.vue'
import { logEvent, events } from '@/services/analytics'
import { formatOrdinal } from '@/language'
import { MAX_SPRAYINGS_ALLOWED } from '@/utils/sprayings'

export default {
  name: 'ProgramItem',

  components: {
    AddProgramModal,
    AddSprayingModal,
    DfCopyModal,
    DfDeleteModal,
    DfButton,
    DfDropdownList,
    ExecuteProgramModal,
    ProgramCost,
    ProgramInfos,
    ProgramSecondaryInfos,
    ProgramSprayingItem,
  },

  props: {
    cropName: {
      required: true,
      type: String,
    },
    fieldId: {
      default: null,
      type: Number,
    },
    isComparisonScreen: {
      default: false,
      type: Boolean,
    },
    program: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      formatOrdinal,
      isAddProgramModalOpened: false,
      isAddSprayingModalOpened: false,
      isCopyModalOpened: false,
      isDeleteProgramModalOpened: false,
      isExecuteProgramModalOpened: false,
      loadingCopyModal: false,
    }
  },

  provide() {
    return {
      getSprayingsLength: this.getSprayingsLength,
    }
  },

  inject: {
    requestPrograms: {
      default: () => [],
      from: 'requestPrograms',
    },
  },

  mounted() {
    if (!this.showProgramActions) {
      const spraying = this.$refs.programSprayings
      spraying.classList.add('no-actions')
    }
  },

  computed: {
    hasInfo() {
      return (
        this.program.pressure ||
        this.program.aerial_web_blight_control ||
        this.program.white_mold_control
      )
    },
    isConfiguredProgram() {
      return this.program.name
    },
    isSingleSpraying() {
      return this.program.sprayings.length === 1
    },
    programActions() {
      const actions = []
      if (this.isConfiguredProgram) {
        actions.push(
          {
            name: this.$t('DiseasesControl.program_item.edit'),
            icon: 'edit',
            action: () => {
              this.isAddProgramModalOpened = true
            },
          },
          {
            name: this.$t('DiseasesControl.program_item.copy_to'),
            icon: 'copy',
            action: () => {
              this.isCopyModalOpened = true
            },
          },
          {
            name: this.$t('DiseasesControl.program_item.duplicate'),
            icon: 'file-export',
            action: async () => {
              const params = {
                field_ids: [this.fieldId],
                program_id: this.program.id,
              }
              try {
                await diseasesControlService.copyProgram(params)
                this.$root.$emit(
                  'notify',
                  'success',
                  this.$t(
                    'DiseasesControl.program_item.successfully_duplicate_program'
                  )
                )
                this.requestPrograms()
              } catch (error) {
                console.error(error)
              }
            },
          },
          {
            name: this.$t('DiseasesControl.program_item.compare_program'),
            icon: 'arrows-alt-h',
            action: () => {
              this.$router.push({
                path: '/diseases_control/compare',
              })
            },
          },
          {
            name: this.$t('DiseasesControl.program_item.print'),
            icon: 'print',
            action: () => {},
          }
        )
      }
      actions.push({
        name: this.$t('DiseasesControl.program_item.delete'),
        color: '#F23528',
        icon: 'trash',
        action: () => {
          this.isDeleteProgramModalOpened = true
        },
      })
      return actions
    },
    programDate() {
      return (
        (this.program.date && this.program.date.formatDate()) ||
        this.program.estimated_sowing_date.formatDate()
      )
    },
    programName() {
      return this.program.name
        ? this.program.name
        : this.$t('DiseasesControl.program_item.not_configured')
    },
    programRate() {
      return this.program.rate ? this.program.rate.toFixed(1) : '0.0'
    },
    showProgramActions() {
      return !this.program.sprayings.length || !this.shouldDisableAddSpraying
    },
    shouldDisableAddSpraying() {
      return !!(
        this.program.sprayings.length &&
        this.program.sprayings.length >=
          MAX_SPRAYINGS_ALLOWED[this.program.sprayings[0].type][this.cropName]
      )
    },
    shouldShowExecuteButton() {
      return this.program.status === 'planning' && !this.isComparisonScreen
    },
  },

  methods: {
    addSpraying() {
      logEvent(events.diseasesControlModule.DiseasesControl.create_spraying)
      this.isAddSprayingModalOpened = true
    },
    closeAddProgramModal() {
      this.isAddProgramModalOpened = false
    },
    closeAddSprayingModal() {
      this.isAddSprayingModalOpened = false
    },
    closeCopyModal() {
      this.isCopyModalOpened = false
    },
    closeDeleteProgramModal() {
      this.isDeleteProgramModalOpened = false
    },
    closeExecuteProgramModal() {
      this.isExecuteProgramModalOpened = false
    },
    async copyProgram(selectedFieldIds) {
      if (!selectedFieldIds.length) {
        this.$root.$emit(
          'notify',
          'warning',
          this.$t('SowingsPlanning.exceptions.no_field_selected')
        )
        return
      }
      const params = {
        field_ids: selectedFieldIds,
        program_id: this.program.id,
      }
      this.loadingCopyModal = true
      try {
        await diseasesControlService.copyProgram(params)
        this.$root.$emit(
          'notify',
          'success',
          this.$t('DiseasesControl.program_item.successfully_copy_program')
        )
        logEvent(events.diseasesControlModule.DiseasesControl.finish_copy)
        this.requestPrograms()
        this.closeCopyModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingCopyModal = false
      }
    },
    configureProgram() {
      logEvent(events.diseasesControlModule.DiseasesControl.configure_program)
      this.isAddProgramModalOpened = true
    },
    async deleteProgram() {
      const params = {
        program_id: this.program.id,
      }
      try {
        await diseasesControlService.deleteProgram(params)
        this.requestPrograms()
        this.closeDeleteProgramModal()
      } catch (error) {
        console.error(error)
      }
    },
    executeInSeason() {
      logEvent(events.diseasesControlModule.DiseasesControl.execute_in_season)
      this.isExecuteProgramModalOpened = true
    },
    getSprayingsLength() {
      return this.program.sprayings.length
    },
  },
}
</script>

<style lang="scss" scoped>
.program-item {
  max-width: 276px;
  min-width: 276px;

  .program__infos {
    padding: 12px 16px 16px 16px;
    border-top: 1px solid #e6e9e6;
    border-left: 1px solid #e6e9e6;
    border-right: 1px solid #e6e9e6;
    border-radius: 12px 12px 0px 0px;

    h2 {
      height: 48px;
      max-width: 242px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    h3 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    span {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
    p {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .program__sprayings {
    .sprayings__action {
      background-color: #f8f9f8;
      padding: 12px 16px;
      border-left: 1px solid #e6e9e6;
      border-right: 1px solid #e6e9e6;

      .v-btn {
        background-color: #fff;
      }
    }
  }
  .program__actions {
    padding: 16px;
    border: 1px solid #e6e9e6;
    border-radius: 0px 0px 12px 12px;

    .actions__add-spraying {
      p {
        background-color: #f8f9f8;
        padding: 8px 16px;
        border-radius: 4px;
        text-align: center;
        color: #1a2b46;
        font-family: 'Rubik';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .add-sprayings__white-mold {
        background-color: #f2f6ff;
        padding: 8px 16px;
        border-radius: 4px;
        text-align: center;
        color: #1a2b46;
        text-align: center;
        font-family: 'Rubik';
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
}
.program__copy-modal {
  padding: 12px 16px;
  background-color: #f8f9f8;
  border: 1px solid #e6e9e6;
  border-radius: 8px;

  h2 {
    overflow: hidden;
    color: #1a2b46;
    text-overflow: ellipsis;
    font-family: 'Rubik';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  p {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  span {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .copy-modal__infos {
    .infos {
      align-items: center;
      justify-content: end;

      @include d(m) {
        justify-content: start;
      }
    }
    p {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
}
.program__sprayings {
  &.no-actions {
    & .program-spraying-item:last-child {
      border-bottom: 1px solid #e6e9e6;
      border-radius: 0px 0px 12px 12px;
    }
  }
}
.v-tooltip__content {
  width: 276px;
  text-align: center;
  background-color: #fff !important;
  padding: 12px;
  opacity: 1 !important;
  box-shadow: 0px 4px 10px 0px rgba(20, 22, 27, 0.1);
}
.tooltip__content {
  color: #5b6459;
  font-family: 'Rubik';
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
</style>
