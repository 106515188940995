<template>
  <div
    class="df-flex-sm program__cost"
    :class="column ? 'df-flex-col' : 'align-center'"
  >
    <span>
      {{ $t('DiseasesControl.program_item.program_cost') }}
    </span>
    <div v-if="!program.sprayings.length" class="program__tag">
      {{ $t('DiseasesControl.program_item.waiting_first_spraying') }}
    </div>
    <div v-else class="df-flex-m align-baseline flex-grow-0">
      <h3>{{ $currency(programCost) }}</h3>
      <span>
        {{ programQuantity.toFixed(1) }}
        {{ $unitMeasures['volume'][$currentLanguage()] }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramCost',

  props: {
    column: {
      default: false,
      type: Boolean,
    },
    program: {
      required: true,
      type: Object,
    },
  },

  computed: {
    programCost() {
      return this.program.sprayings.reduce((acc, spraying) => {
        acc += spraying.products.reduce((acc2, product) => {
          acc2 += product.dose_per_hectare * product.price_per_liter
          return acc2
        }, 0)
        return acc
      }, 0)
    },
    programQuantity() {
      return this.program.sprayings.reduce((acc, spraying) => {
        acc += spraying.products.reduce((acc2, product) => {
          acc2 += product.dose_per_hectare
          return acc2
        }, 0)
        return acc
      }, 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.program__cost {
  h3 {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  span {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}
.program__tag {
  border: 1px solid #e6e9e6;
  border-radius: 4px;
  padding: 2px 4px;
  width: fit-content;
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
</style>
