class EfficacyChart extends HTMLElement {
  static get observedAttributes() {
    return ['colorful', 'efficacies']
  }

  attributeChangedCallback(name, _, newValue) {
    // Colorful props must be passed first in any <efficacy-chart />
    // component declaration to take effect
    if (name === 'colorful') {
      this._colorful = !!newValue
    }
    if (name === 'efficacies') {
      const efficacies = JSON.parse(newValue)
      this._efficacies = [...efficacies]
      const container = this.shadowRoot.querySelector('#chart')
      container.innerHTML = ''

      for (let index = 0; index < efficacies.length; index++) {
        const chartItem = document.createElement('div')
        const chartTitle = document.createElement('div')
        const h1 = document.createElement('h1')
        const divider = document.createElement('div')
        const span = document.createElement('span')
        const percentageBar = document.createElement('div')

        const percentage = Math.round(efficacies[index].efficacy * 10000) / 100

        chartTitle.classList.add('chart__title')
        chartItem.classList.add('chart__item')
        divider.classList.add('divider')
        percentageBar.classList.add('bar')
        percentageBar.style.width = `${percentage}%`

        h1.textContent = efficacies[index].disease_name
        span.textContent = `${percentage}%`

        chartTitle.appendChild(h1)
        chartTitle.appendChild(divider)
        chartTitle.appendChild(span)

        chartItem.appendChild(chartTitle)
        chartItem.appendChild(percentageBar)

        container.appendChild(chartItem)
      }
    }
  }

  connectedCallback() {
    if (this._colorful) {
      this.colorBars()
    }
  }

  colorBars() {
    const container = this.shadowRoot.querySelector('#chart')
    const containerWidth = container.getBoundingClientRect().width
    const stopPercentage = 50
    const stop = `${(containerWidth * stopPercentage) / 100}px`
    const end = `${containerWidth}px`

    let percentageBar
    for (let index = 0; index < this._efficacies.length; index++) {
      percentageBar = this.shadowRoot.querySelectorAll('.bar')[index]
      percentageBar.style.background = `linear-gradient(to right, #F23528 0, #F2CD12 ${stop}, #39AF49 ${end})`
    }
  }

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
    this._colorful = false
    this._efficacies = []
    this.shadowRoot.innerHTML = `
      <style scoped>
        .efficacy-chart {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        .chart__item {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
        .chart__title {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        .divider {
          height: 18px;
          width: 1px;
          background-color: #E6E9E6;
          margin: 0px 7px; 
        }
        .bar {
          height: 6px;
          background-color: #E6E9E6;
          border-radius: 8px;
        }
        h1 {
          color: #1A2B46;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          margin: 0px;
        }
        span {
          color: #5B6459;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      </style>
      <div id="chart" class="efficacy-chart">
      </div>
    `
  }
}

customElements.define('efficacy-chart', EfficacyChart)
