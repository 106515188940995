<template>
  <div class="df-flex-l df-flex-col program-spraying-item">
    <section class="df-flex-sm df-flex-col spraying-item__summary">
      <div class="df-flex-none justify-space-between align-start flex-nowrap">
        <div class="df-flex-sm align-baseline">
          <h1>
            {{ formatOrdinal(spraying.spraying_order) }}
            {{ $t('DiseasesControl.program_spraying_item.spraying') }}
          </h1>
          <span>
            {{ $currency(sprayingCost) }}/{{
              $unitMeasures['area'][$currentLanguage()]
            }}
          </span>
        </div>
        <v-btn v-if="expanded" icon small @click="toggleExpandedSprayingItem">
          <font-awesome-icon color="#5B6459" icon="chevron-up" />
        </v-btn>
        <v-btn v-else icon small @click="toggleExpandedSprayingItem">
          <font-awesome-icon color="#5B6459" icon="chevron-right" />
        </v-btn>
      </div>
      <div
        class="df-flex-xm"
        :class="isComparisonScreen ? 'df-flex-col' : 'align-center'"
      >
        <div class="flex-grow-1">
          <span>{{ sprayingDate }}</span>
        </div>
        <div class="df-flex-xm flex-grow-0 align-center flex-nowrap">
          <div class="program__tag">
            {{ diseaseName(spraying.main_disease_id) }}
          </div>
          <div v-if="spraying.secondary_disease_id" class="program__tag">
            {{ diseaseName(spraying.secondary_disease_id) }}
          </div>
        </div>
      </div>
    </section>
    <v-divider v-if="expanded" />
    <section
      v-if="expanded"
      class="df-flex-l df-flex-col spraying-item__products"
    >
      <div class="df-flex-none justify-space-between align-center">
        <h2>{{ $t('DiseasesControl.program_spraying_item.products') }}</h2>
        <df-dropdown-list
          v-if="!isComparisonScreen"
          icon="ellipsis-v"
          :items="sprayingActions"
        />
      </div>
      <div class="df-flex-sm df-flex-col">
        <div
          v-for="product in visibleProducts"
          :key="product.id"
          class="df-flex-xm align-center"
        >
          <span>{{ product.name }}</span>
          <span>•</span>
          <span>
            {{ product.dose_per_hectare }}/{{
              $unitMeasures['area'][$currentLanguage()]
            }}
          </span>
        </div>
        <v-tooltip v-if="shouldShowTooltip" bottom color="#fff">
          <template #activator="{ on }">
            <div v-on="on" class="program__tag">
              {{
                $t('DiseasesControl.program_spraying_item.more_products', [
                  spraying.products.length - visibleProductsQtt,
                ])
              }}
            </div>
          </template>
          <div
            v-for="product in spraying.products.slice(
              visibleProductsQtt,
              spraying.products.length
            )"
            :key="product.id"
            class="df-flex-xm align-center products__tooltip"
          >
            <span>{{ product.name }}</span>
            <span>•</span>
            <span>
              {{ product.dose_per_hectare }}/{{
                $unitMeasures['area'][$currentLanguage()]
              }}
            </span>
          </div>
        </v-tooltip>
        <div v-else-if="isComparisonScreen" class="program__tag">
          {{
            $t('DiseasesControl.program_spraying_item.more_products', [
              spraying.products.length - visibleProductsQtt,
            ])
          }}
        </div>
      </div>
    </section>
    <v-divider v-if="expanded" />
    <section
      v-if="expanded"
      class="df-flex-l df-flex-col spraying-item__efficacies"
    >
      <h2>{{ $t('DiseasesControl.program_spraying_item.efficacies') }}</h2>
      <efficacy-chart
        class="efficacies-chart"
        colorful="true"
        :efficacies="efficacies"
      />
    </section>
    <add-spraying-modal
      v-if="isEditSprayingModalOpened"
      :crop-name="cropName"
      :edited-spraying="spraying"
      @close="closeEditSprayingModal"
    />
    <df-delete-modal
      v-if="isDeleteSprayingModalOpened"
      :text="$t('SowingView.delete_modal.text')"
      :title="$t('SowingView.delete_modal.title')"
      @action-click="deleteSpraying"
      @close="closeDeleteSprayingModal"
    />
  </div>
</template>

<script>
const AddSprayingModal = () =>
  import('@/modules/sowingView/components/modal/AddSprayingModal.vue')
const DfDeleteModal = () => import('@/lib/Modal/DfDeleteModal.vue')
import DfDropdownList from '@/lib/components/List/Dropdown/DfDropdownList.vue'
import sowingViewService from '@/modules/sowingView/services/sowingViewService.js'
import '@/components/Efficacies/EfficacyChart.js'
import { formatOrdinal } from '@/language'
import { mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'ProgramSprayingItem',

  components: {
    AddSprayingModal,
    DfDeleteModal,
    DfDropdownList,
  },

  props: {
    cropName: {
      required: true,
      type: String,
    },
    isComparisonScreen: {
      default: false,
      type: Boolean,
    },
    spraying: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      isDeleteSprayingModalOpened: false,
      isEditSprayingModalOpened: false,
      expanded: false,
      formatOrdinal,
      visibleProductsQtt: 3,
    }
  },

  inject: {
    requestPrograms: {
      default: () => [],
      from: 'requestPrograms',
    },
  },

  created() {
    if (this.isComparisonScreen) this.visibleProductsQtt = 1
  },

  computed: {
    ...mapGetters('seasonsCrops', ['seasonCropsDiseases']),
    efficacies() {
      let translatedEfficacies = [...this.spraying.efficacies]
      translatedEfficacies = translatedEfficacies.map((efficacy) => {
        return {
          disease_name: this.$t(`diseases.${efficacy.disease_name}`),
          efficacy: efficacy.efficacy.toFixed(2),
        }
      })
      return JSON.stringify(translatedEfficacies)
    },
    shouldShowTooltip() {
      return this.spraying.products.length > this.visibleProductsQtt
    },
    sprayingActions() {
      return [
        {
          name: this.$t('DiseasesControl.program_spraying_item.edit'),
          icon: 'edit',
          action: () => {
            logEvent(events.sowingViewModule.SowingView.edit_spraying)
            this.isEditSprayingModalOpened = true
          },
        },
        {
          name: this.$t('DiseasesControl.program_spraying_item.delete'),
          color: '#F23528',
          icon: 'trash',
          action: () => {
            logEvent(events.sowingViewModule.SowingView.delete_spraying)
            this.isDeleteSprayingModalOpened = true
          },
        },
      ]
    },
    sprayingCost() {
      const cost = this.spraying.products.reduce((acc, product) => {
        acc += product.dose_per_hectare * product.price_per_liter
        return acc
      }, 0)
      return Number(cost.toFixed(2))
    },
    sprayingDate() {
      const date = this.spraying.applied_date || this.spraying.ideal_date
      return date.formatDate()
    },
    visibleProducts() {
      return this.spraying.products.slice(0, this.visibleProductsQtt)
    },
  },

  methods: {
    closeDeleteSprayingModal() {
      this.isDeleteSprayingModalOpened = false
    },
    closeEditSprayingModal() {
      this.isEditSprayingModalOpened = false
    },
    async deleteSpraying() {
      const params = {
        spraying_id: this.spraying.id,
      }
      try {
        await sowingViewService.deleteSpraying(params)
        this.requestPrograms()
      } catch (error) {
        console.error(error)
      }
    },
    diseaseName(diseaseId) {
      const curDisease = this.seasonCropsDiseases.find(
        (disease) => disease.id == diseaseId
      )
      if (curDisease) return this.$t(`diseases.${curDisease.code}`)
      else return '---'
    },
    toggleExpandedSprayingItem() {
      this.expanded = !this.expanded
    },
  },
}
</script>

<style lang="scss" scoped>
.program-spraying-item {
  padding: 16px;
  border-top: 1px solid #e6e9e6;
  border-left: 1px solid #e6e9e6;
  border-right: 1px solid #e6e9e6;

  .spraying-item__summary {
    h1 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
    span {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .spraying-item__products {
    h2 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
    span {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .spraying-item__efficacies {
    h2 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
    .efficacies-chart {
      max-height: 208px;
      overflow-y: auto;
    }
  }
}
.program__tag {
  border: 1px solid #e6e9e6;
  border-radius: 4px;
  padding: 2px 4px;
  width: fit-content;
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.v-btn {
  &.expanded {
    transform: rotate(-90deg);
  }
}
.v-tooltip__content {
  background-color: #fff !important;
  padding: 12px;
  opacity: 1 !important;
  box-shadow: 0px 4px 10px 0px rgba(20, 22, 27, 0.1);
}
.products__tooltip {
  span {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
}
::-webkit-scrollbar {
  width: 4px !important;
}
</style>
