<template>
  <div class="df-flex-sm df-flex-xm-m align-center program-secondary-infos">
    <div
      v-if="program.pressure"
      class="df-flex-sm align-center"
      :class="justified"
    >
      <font-awesome-icon :color="pressureIconColor" icon="minus" />
      <p>
        {{ $t(`DiseasesControl.program_item.${program.pressure}`) }}
        {{ $t('DiseasesControl.program_item.pressure') }}
      </p>
    </div>
    <div class="df-flex-sm flex-grow-0 align-center flex-nowrap">
      <div v-if="program.aerial_web_blight_control" class="program__tag">
        {{ $t('diseases.soybean_aerial_web_blight') }}
      </div>
      <div v-if="program.white_mold_control" class="program__tag">
        {{ $t('diseases.soybean_white_mold') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramSecondaryInfos',

  props: {
    justifyBetween: {
      default: false,
      type: Boolean,
    },
    program: {
      required: true,
      type: Object,
    },
  },

  computed: {
    justified() {
      return this.justifyBetween ? 'flex-grow-1' : 'flex-grow-0'
    },
    pressureIconColor() {
      const pressureColors = {
        high: '#39AF49',
        medium: '#4A76BC',
        low: '#F2CD12',
      }
      return pressureColors[this.program.pressure]
    },
  },
}
</script>

<style lang="scss" scoped>
.program-secondary-infos {
  align-items: center;
  justify-content: start;

  p {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
}
.program__tag {
  border: 1px solid #e6e9e6;
  border-radius: 4px;
  padding: 2px 4px;
  width: fit-content;
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
</style>
